"use strict";

(($, win, doc)=> {    // JQ ES6 sw Pack

  let $win = $(win),
      $doc = $(doc),
      $html = $('html'),
      $body = $('body'),
      swns = {
        theDate(_today, _flag) {
          let today = new Date(_today);
          let month = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December"
          ];
          let day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          let y = today.getFullYear();
          let m = today.getMonth() + 1;
          let ml = today.getMonth();
          let d = today.getDate();
          let w = today.getDay();
          let H = today.getHours();
          let M = today.getMinutes();
          let S = today.getSeconds();
          if (m < 10) { m = "0" + m; }
          if (d < 10) { d = "0" + d; }
          if (H < 10) { H = "0" + H; }
          if (M < 10) { M = "0" + M; }
          if (S < 10) { S = "0" + S; }
          //return y + ` ${month[ml]} ` + `${day[w]}.` + m + "." + d; // + "/" + H + ":" + M + "." + S;
          if (_flag) {
            return `${month[ml]} ` + y;
          } else {
            return y + `.` + m + "." + d; // + "/" + H + ":" + M + "." + S;
          }
        }
      }; // swns{}

  
  let monthChkArr = [];
  let monthWord = "";
  let monthChkTr = "";
  const makeWpJsonRes =()=>  {
    const _xhrJson = $.getJSON("https://kiitos.or.jp/wp-json/wp/v2/lab?filter[posts_per_page]=5", null, (dict, status)=> { return _xhrJson.resolve; });
    const $addDeslab = $("#add-deslab");
    $.when(_xhrJson).done((_xhrRes) => {
      $addDeslab.empty();
      _xhrRes.forEach((response, i) => {
        monthWord = swns.theDate(response.date, "true");
        monthChkArr.push(monthWord);
        if ( monthChkArr[i - 1] === monthWord ) {
          monthChkTr = ``;
        } else {
          monthChkTr = `
      <li class="monthChkTr">${swns.theDate(response.date, "true")}</li>
          `;
        }
        $addDeslab.append(`
      ${monthChkTr}
      <li class="dt">${swns.theDate(response.date)}</li>
      <li class="dd"><a href='${response.link}' target="_blank">${response.excerpt.rendered}</a></li>
      `);
        
      });
      
      /*for (let i = 0, l = _xhrRes; i < l; i++) {
      } // for*/
    
    }).fail((error) => {
      console.info("$.when.fail-error:", error);
    });
  };
  
  const makeWpJsonResSlide = (_cid) => {
    let _xhrJson;
    if (_cid === '1') {
      _xhrJson = $.getJSON("https://kiitos.or.jp/wp-json/wp/v2/lab?_embed&per_page=6", null, (dict, status) => { return _xhrJson.resolve; });
      
    } else if (_cid === '2') {
      _xhrJson = $.getJSON("https://kiitos.or.jp/wp-json/wp/v2/posts?_embed&per_page=6", null, (dict, status) => { return _xhrJson.resolve; });
      
    } else if (_cid === '3') {
      _xhrJson = $.getJSON("https://kiitos.or.jp/wp-json/wp/v2/lab?_embed&per_page=6", null, (dict, status) => { return _xhrJson.resolve; });
    }
    
    let thumbs = "";
    let $_cid;
    $.when(_xhrJson).done((_xhrRes) => {
      _xhrRes.forEach((response, i) => {
        $_cid = $(`div.cid${_cid}-${i}`);
        if ( response['_embedded']['wp:featuredmedia'] ) {
          thumbs = `<a href='${response.link}' target="_blank"><p class="ti">${response.title.rendered}</p></a>`;
          $_cid.css("background-image", `url(${response['_embedded']['wp:featuredmedia'][0]['source_url']})`);
        } else {
          thumbs = `<a href='${response.link}' target="_blank"><p class="ti">${response.title.rendered}</p></a>`;
          $_cid.css("background-image", `url(/images/_noImg.png)`);
        }
        $_cid.html(`${thumbs}`);
      });
      
      $(`#carou${_cid}.owl-carousel`).owlCarousel({
        loop      : true,
        margin    : 10,
        autoplay  : true,
        autoplaySpeed: 1111,
        smartscroll: 3456,
        nav       : true,
        navText   : [
          '<i class="fa fa-arrow-circle-left"></i>',
          '<i class="fa fa-arrow-circle-right"></i>'
        ],
        responsive: {
          0   : {
            items: 1
          },
          768 : {
            items: 3
          },
          769: {
            items: 5
          }
        }
      })
      
    }).fail((error) => {
      console.info("$.when.fail-error:", error);
    });
    
  };


  // do it DRF
  $(()=> {
  
    makeWpJsonRes();
    //$win.on("load", function () {});
    makeWpJsonResSlide('1');
    makeWpJsonResSlide('2');
    makeWpJsonResSlide('3');
    
    
  }); // DRF }
  
})(jQuery, window, document);

